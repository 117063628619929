.card {
  padding: 1rem;
  /* border: 1px solid #000526;
  border-radius:15px;
  background: #000526; */
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
}

.card-header {
  font-size: 1.032rem;
  line-height: 1.355rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border-bottom: 1px solid #080b27;
}

.card-divider {
  height: 1px;
  background: #000526;
  margin: 0.7rem -1rem;
}

.card-body {
}
