.Orders-list {
  width: 100%;
  background: linear-gradient(45deg, rgba(11,5,55,0.6) 0%, rgba(21,3,48,0.6) 100%);
  font-size: 0.9rem;
}

.Orders-list.small {
	display: none;
}

table.Orders-list {
  border-collapse: collapse;
  border: 1px solid #1c0e6a;
}

table.Orders-list th, table.Orders-list td {
  font-weight: normal;
  text-align: left;
  padding: 0.7rem 1rem;
}

table.Orders-list tr {
  background: linear-gradient(90deg, rgba(57,28,147,0.1) 0%, rgba(66,24,140,0.1) 100%);
  border-bottom: 1px solid rgba(28, 14, 106, 0.5);
}

table.Orders-list tr:first-child {
  border-bottom: 1px solid rgba(28, 14, 106, 1);
}

table.Orders-list tr:last-child {
  border-bottom: 1px solid rgba(28, 14, 106, 1);
}

.Orders-list-item:hover {
  background: linear-gradient(90deg, rgba(93,43,255,0.5) 0%, rgba(184,15,150,0.5) 100%);
}

.Orders-list-item-error {
  color: #ff2491;
}

.Orders-list-item-type {
  width: 60px;
}

table.Orders-list tr.Orders-list-header {
  background: none;
}

table.Orders-list th {
  opacity: 0.7;
}

button.Orders-list-action {
  font-size: 0.9rem;
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: #8b7fe3;
  opacity: 0.9;
}

button.Orders-list-action:hover {
  color: #d4b3f5;
  opacity: 1;
}

.Orders-list-modal-button {
  margin-top: 10px;
}

/* don't know how to solve other way */
.Orders-list-modal .Exchange-swap-input {
  width: 180px;
}

.Orders-list-modal-warning {
  color: #fa3c58;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 10px;
}

.Orders-list-modal .Modal-content {
  width: 22rem;
}

@media (max-width: 1000px) {
  .Orders-list.small {
    display: block;
  }

  .Orders-list.large {
  	display: none;
  }
}
