.asset-menu-items {
  position: absolute;
  border: 1px solid #080b27;
  border-radius: 4px;
  background: var(--dark-blue);
  outline: none;
  z-index: 100;
}

.dropdown-arrow {
  margin-left: 0.5rem;
  cursor: pointer;
  color: white;
  opacity: 0.8;
}
.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  padding: 8.5px 8px;
  background: #080b27;
}
.asset-item:hover {
  background: #080b27;
  color: rgb(59 130 245);
  border-radius: 4px;
}
.asset-item p {
  margin: 0;
  margin-left: 5px;
}
