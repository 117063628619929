.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.user-address {
  margin-left: 10px;
  margin-right: 10px;
}

.App-header-user-address:hover {
  background: #080b27;
}

.menu-items {
  position: absolute;
  right: 0;
  top: 43px;
  min-width: 10rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 4px;
  background: #080b27;
  border: 1px solid #080b27;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  padding-bottom: 15px;
  font-size: 14px;
  padding: 8.5px 8px;
  /* border: 1px solid transparent; */
  border-radius: 4px;
}
.menu-item:hover {
  background: #080b27 !important;
  /* border: 1px solid #4275a8; */
  border-radius: 4 px;
  opacity: 1;
  color: #eee;
}
.menu-item > p {
  margin: 0px;
  padding-left: 10px;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    display: none;
  }
  .address-btn {
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
}
