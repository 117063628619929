.ExchangeWalletTokens {
  max-height: 100%;
  overflow: auto;
}

.ExchangeWalletTokens-token-name {
  margin-right: 1rem;
}

.ExchangeWalletTokens-box {
  display: block;
  cursor: pointer;
  border-radius: 6px;
  text-align: right;
  white-space: nowrap;
}

.ExchangeWalletTokens-box:hover {
  color: #8763ff;
}

.ExchangeWalletTokens-token-row {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.ExchangeWalletTokens-token-row:hover {
  background: linear-gradient(90deg, rgba(93,43,255,0.5) 0%, rgba(184,15,150,0.5) 100%);
}

.ExchangeWalletTokens-top-row {
  font-size: 1rem;
  display: grid;
  grid-template-columns: auto auto;
}

.ExchangeWalletTokens-content-row {
  margin-top: 0.2rem;
  font-size: 0.9rem;
  opacity: 0.7;
  display: grid;
  grid-template-columns: auto auto;
}
