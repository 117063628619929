.BuyEQUALEQUITY {
    /* background: #080b27; */
    justify-content: space-between;
}

.BuyEQUALEQUITY-container {
    padding: 3rem 0 2rem 0;
    width: 100%;
}

.BuyEQUALEQUITY-container .section-title-content {
    justify-content: flex-start;
}

@media (max-width: 425px) {
    .BuyEQUALEQUITY-container {
        padding-left: 16px!important;
        padding-right: 16px!important;
    }
}
.buypage-container-pd{
    padding-left: 16px!important;
    padding-right: 16px!important;
}

.default-btnhome.read-more {
    color: rgb(10 211 215) !important;
    border: 1px solid rgba(10, 211, 215, 0.6) !important;
    background: none !important;
    border-radius: 0.5rem !important;
    transition-timing-function: linear;
    transition-property: all;
    transition-delay: 150ms;
  }

.default-btnhome.read-more:hover {
    color: rgb(10 211 215) !important;
    border: 1px solid rgba(10, 211, 215, 1) !important;
    background: none !important;  }

.default-btnhome {
    border-radius: 0.5rem !important;
  }