.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 0.9rem;
  border-radius: 15px;
  overflow: hidden;
  color: #fff;
  background: #142043db;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.3rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
}

.Tab.inline .Tab-option:nth-child(2) {
  cursor: pointer;
  display: none;
  margin-right: 1rem;
}

.Tab.block .Tab-option:hover {
  color: #fff;
  background: #142043;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: rgb(59 130 246);
  color: #fff;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
