/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.blue-waves {
    height: 500px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Anton', sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    position: absolute;
    top: 100px;
    z-index: 0;
    width: 100%;
    zoom: 200%;
    opacity:0.7;
}
.sea {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-filter: drop-shadow(0 -7px 10px #2af4eb) blur(3px) contrast(5);
            filter: drop-shadow(0 -7px 10px #2af4eb) blur(3px) contrast(5);
}
.wave_graphic {
    width: 10px;
    height: 30px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(150, 220, 255, 0.5)), to(rgba(150, 220, 255, 0.2)));
    background: -o-linear-gradient(top, rgba(150, 220, 255, 0.5), rgba(150, 220, 255, 0.2));
    background: linear-gradient(180deg, rgba(150, 220, 255, 0.5), rgba(150, 220, 255, 0.2));
}
.wave_fade {
    -webkit-animation: fade 1400ms ease-in-out infinite alternate;
            animation: fade 1400ms ease-in-out infinite alternate;
}
.wave_skew {
    -webkit-animation: skew 4000ms ease-in-out infinite alternate;
            animation: skew 4000ms ease-in-out infinite alternate;
}
.wave_translate {
    -webkit-animation: translate 4000ms ease-in-out infinite alternate;
            animation: translate 4000ms ease-in-out infinite alternate;
}
.wave:nth-child(1) .wave_fade {
    -webkit-animation-delay: -20ms;
            animation-delay: -20ms;
}
.wave:nth-child(1) .wave_skew {
    -webkit-animation-delay: -20ms;
            animation-delay: -20ms;
}
.wave:nth-child(1) .wave_translate {
    -webkit-animation-delay: -1020ms;
            animation-delay: -1020ms;
}
.wave:nth-child(2) .wave_fade {
    -webkit-animation-delay: -40ms;
            animation-delay: -40ms;
}
.wave:nth-child(2) .wave_skew {
    -webkit-animation-delay: -40ms;
            animation-delay: -40ms;
}
.wave:nth-child(2) .wave_translate {
    -webkit-animation-delay: -1040ms;
            animation-delay: -1040ms;
}
.wave:nth-child(3) .wave_fade {
    -webkit-animation-delay: -60ms;
            animation-delay: -60ms;
}
.wave:nth-child(3) .wave_skew {
    -webkit-animation-delay: -60ms;
            animation-delay: -60ms;
}
.wave:nth-child(3) .wave_translate {
    -webkit-animation-delay: -1060ms;
            animation-delay: -1060ms;
}
.wave:nth-child(4) .wave_fade {
    -webkit-animation-delay: -80ms;
            animation-delay: -80ms;
}
.wave:nth-child(4) .wave_skew {
    -webkit-animation-delay: -80ms;
            animation-delay: -80ms;
}
.wave:nth-child(4) .wave_translate {
    -webkit-animation-delay: -1080ms;
            animation-delay: -1080ms;
}
.wave:nth-child(5) .wave_fade {
    -webkit-animation-delay: -100ms;
            animation-delay: -100ms;
}
.wave:nth-child(5) .wave_skew {
    -webkit-animation-delay: -100ms;
            animation-delay: -100ms;
}
.wave:nth-child(5) .wave_translate {
    -webkit-animation-delay: -1100ms;
            animation-delay: -1100ms;
}
.wave:nth-child(6) .wave_fade {
    -webkit-animation-delay: -120ms;
            animation-delay: -120ms;
}
.wave:nth-child(6) .wave_skew {
    -webkit-animation-delay: -120ms;
            animation-delay: -120ms;
}
.wave:nth-child(6) .wave_translate {
    -webkit-animation-delay: -1120ms;
            animation-delay: -1120ms;
}
.wave:nth-child(7) .wave_fade {
    -webkit-animation-delay: -140ms;
            animation-delay: -140ms;
}
.wave:nth-child(7) .wave_skew {
    -webkit-animation-delay: -140ms;
            animation-delay: -140ms;
}
.wave:nth-child(7) .wave_translate {
    -webkit-animation-delay: -1140ms;
            animation-delay: -1140ms;
}
.wave:nth-child(8) .wave_fade {
    -webkit-animation-delay: -160ms;
            animation-delay: -160ms;
}
.wave:nth-child(8) .wave_skew {
    -webkit-animation-delay: -160ms;
            animation-delay: -160ms;
}
.wave:nth-child(8) .wave_translate {
    -webkit-animation-delay: -1160ms;
            animation-delay: -1160ms;
}
.wave:nth-child(9) .wave_fade {
    -webkit-animation-delay: -180ms;
            animation-delay: -180ms;
}
.wave:nth-child(9) .wave_skew {
    -webkit-animation-delay: -180ms;
            animation-delay: -180ms;
}
.wave:nth-child(9) .wave_translate {
    -webkit-animation-delay: -1180ms;
            animation-delay: -1180ms;
}
.wave:nth-child(10) .wave_fade {
    -webkit-animation-delay: -200ms;
            animation-delay: -200ms;
}
.wave:nth-child(10) .wave_skew {
    -webkit-animation-delay: -200ms;
            animation-delay: -200ms;
}
.wave:nth-child(10) .wave_translate {
    -webkit-animation-delay: -1200ms;
            animation-delay: -1200ms;
}
.wave:nth-child(11) .wave_fade {
    -webkit-animation-delay: -220ms;
            animation-delay: -220ms;
}
.wave:nth-child(11) .wave_skew {
    -webkit-animation-delay: -220ms;
            animation-delay: -220ms;
}
.wave:nth-child(11) .wave_translate {
    -webkit-animation-delay: -1220ms;
            animation-delay: -1220ms;
}
.wave:nth-child(12) .wave_fade {
    -webkit-animation-delay: -240ms;
            animation-delay: -240ms;
}
.wave:nth-child(12) .wave_skew {
    -webkit-animation-delay: -240ms;
            animation-delay: -240ms;
}
.wave:nth-child(12) .wave_translate {
    -webkit-animation-delay: -1240ms;
            animation-delay: -1240ms;
}
.wave:nth-child(13) .wave_fade {
    -webkit-animation-delay: -260ms;
            animation-delay: -260ms;
}
.wave:nth-child(13) .wave_skew {
    -webkit-animation-delay: -260ms;
            animation-delay: -260ms;
}
.wave:nth-child(13) .wave_translate {
    -webkit-animation-delay: -1260ms;
            animation-delay: -1260ms;
}
.wave:nth-child(14) .wave_fade {
    -webkit-animation-delay: -280ms;
            animation-delay: -280ms;
}
.wave:nth-child(14) .wave_skew {
    -webkit-animation-delay: -280ms;
            animation-delay: -280ms;
}
.wave:nth-child(14) .wave_translate {
    -webkit-animation-delay: -1280ms;
            animation-delay: -1280ms;
}
.wave:nth-child(15) .wave_fade {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
}
.wave:nth-child(15) .wave_skew {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
}
.wave:nth-child(15) .wave_translate {
    -webkit-animation-delay: -1300ms;
            animation-delay: -1300ms;
}
.wave:nth-child(16) .wave_fade {
    -webkit-animation-delay: -320ms;
            animation-delay: -320ms;
}
.wave:nth-child(16) .wave_skew {
    -webkit-animation-delay: -320ms;
            animation-delay: -320ms;
}
.wave:nth-child(16) .wave_translate {
    -webkit-animation-delay: -1320ms;
            animation-delay: -1320ms;
}
.wave:nth-child(17) .wave_fade {
    -webkit-animation-delay: -340ms;
            animation-delay: -340ms;
}
.wave:nth-child(17) .wave_skew {
    -webkit-animation-delay: -340ms;
            animation-delay: -340ms;
}
.wave:nth-child(17) .wave_translate {
    -webkit-animation-delay: -1340ms;
            animation-delay: -1340ms;
}
.wave:nth-child(18) .wave_fade {
    -webkit-animation-delay: -360ms;
            animation-delay: -360ms;
}
.wave:nth-child(18) .wave_skew {
    -webkit-animation-delay: -360ms;
            animation-delay: -360ms;
}
.wave:nth-child(18) .wave_translate {
    -webkit-animation-delay: -1360ms;
            animation-delay: -1360ms;
}
.wave:nth-child(19) .wave_fade {
    -webkit-animation-delay: -380ms;
            animation-delay: -380ms;
}
.wave:nth-child(19) .wave_skew {
    -webkit-animation-delay: -380ms;
            animation-delay: -380ms;
}
.wave:nth-child(19) .wave_translate {
    -webkit-animation-delay: -1380ms;
            animation-delay: -1380ms;
}
.wave:nth-child(20) .wave_fade {
    -webkit-animation-delay: -400ms;
            animation-delay: -400ms;
}
.wave:nth-child(20) .wave_skew {
    -webkit-animation-delay: -400ms;
            animation-delay: -400ms;
}
.wave:nth-child(20) .wave_translate {
    -webkit-animation-delay: -1400ms;
            animation-delay: -1400ms;
}
.wave:nth-child(21) .wave_fade {
    -webkit-animation-delay: -420ms;
            animation-delay: -420ms;
}
.wave:nth-child(21) .wave_skew {
    -webkit-animation-delay: -420ms;
            animation-delay: -420ms;
}
.wave:nth-child(21) .wave_translate {
    -webkit-animation-delay: -1420ms;
            animation-delay: -1420ms;
}
.wave:nth-child(22) .wave_fade {
    -webkit-animation-delay: -440ms;
            animation-delay: -440ms;
}
.wave:nth-child(22) .wave_skew {
    -webkit-animation-delay: -440ms;
            animation-delay: -440ms;
}
.wave:nth-child(22) .wave_translate {
    -webkit-animation-delay: -1440ms;
            animation-delay: -1440ms;
}
.wave:nth-child(23) .wave_fade {
    -webkit-animation-delay: -460ms;
            animation-delay: -460ms;
}
.wave:nth-child(23) .wave_skew {
    -webkit-animation-delay: -460ms;
            animation-delay: -460ms;
}
.wave:nth-child(23) .wave_translate {
    -webkit-animation-delay: -1460ms;
            animation-delay: -1460ms;
}
.wave:nth-child(24) .wave_fade {
    -webkit-animation-delay: -480ms;
            animation-delay: -480ms;
}
.wave:nth-child(24) .wave_skew {
    -webkit-animation-delay: -480ms;
            animation-delay: -480ms;
}
.wave:nth-child(24) .wave_translate {
    -webkit-animation-delay: -1480ms;
            animation-delay: -1480ms;
}
.wave:nth-child(25) .wave_fade {
    -webkit-animation-delay: -500ms;
            animation-delay: -500ms;
}
.wave:nth-child(25) .wave_skew {
    -webkit-animation-delay: -500ms;
            animation-delay: -500ms;
}
.wave:nth-child(25) .wave_translate {
    -webkit-animation-delay: -1500ms;
            animation-delay: -1500ms;
}
.wave:nth-child(26) .wave_fade {
    -webkit-animation-delay: -520ms;
            animation-delay: -520ms;
}
.wave:nth-child(26) .wave_skew {
    -webkit-animation-delay: -520ms;
            animation-delay: -520ms;
}
.wave:nth-child(26) .wave_translate {
    -webkit-animation-delay: -1520ms;
            animation-delay: -1520ms;
}
.wave:nth-child(27) .wave_fade {
    -webkit-animation-delay: -540ms;
            animation-delay: -540ms;
}
.wave:nth-child(27) .wave_skew {
    -webkit-animation-delay: -540ms;
            animation-delay: -540ms;
}
.wave:nth-child(27) .wave_translate {
    -webkit-animation-delay: -1540ms;
            animation-delay: -1540ms;
}
.wave:nth-child(28) .wave_fade {
    -webkit-animation-delay: -560ms;
            animation-delay: -560ms;
}
.wave:nth-child(28) .wave_skew {
    -webkit-animation-delay: -560ms;
            animation-delay: -560ms;
}
.wave:nth-child(28) .wave_translate {
    -webkit-animation-delay: -1560ms;
            animation-delay: -1560ms;
}
.wave:nth-child(29) .wave_fade {
    -webkit-animation-delay: -580ms;
            animation-delay: -580ms;
}
.wave:nth-child(29) .wave_skew {
    -webkit-animation-delay: -580ms;
            animation-delay: -580ms;
}
.wave:nth-child(29) .wave_translate {
    -webkit-animation-delay: -1580ms;
            animation-delay: -1580ms;
}
.wave:nth-child(30) .wave_fade {
    -webkit-animation-delay: -600ms;
            animation-delay: -600ms;
}
.wave:nth-child(30) .wave_skew {
    -webkit-animation-delay: -600ms;
            animation-delay: -600ms;
}
.wave:nth-child(30) .wave_translate {
    -webkit-animation-delay: -1600ms;
            animation-delay: -1600ms;
}
.wave:nth-child(31) .wave_fade {
    -webkit-animation-delay: -620ms;
            animation-delay: -620ms;
}
.wave:nth-child(31) .wave_skew {
    -webkit-animation-delay: -620ms;
            animation-delay: -620ms;
}
.wave:nth-child(31) .wave_translate {
    -webkit-animation-delay: -1620ms;
            animation-delay: -1620ms;
}
.wave:nth-child(32) .wave_fade {
    -webkit-animation-delay: -640ms;
            animation-delay: -640ms;
}
.wave:nth-child(32) .wave_skew {
    -webkit-animation-delay: -640ms;
            animation-delay: -640ms;
}
.wave:nth-child(32) .wave_translate {
    -webkit-animation-delay: -1640ms;
            animation-delay: -1640ms;
}
.wave:nth-child(33) .wave_fade {
    -webkit-animation-delay: -660ms;
            animation-delay: -660ms;
}
.wave:nth-child(33) .wave_skew {
    -webkit-animation-delay: -660ms;
            animation-delay: -660ms;
}
.wave:nth-child(33) .wave_translate {
    -webkit-animation-delay: -1660ms;
            animation-delay: -1660ms;
}
.wave:nth-child(34) .wave_fade {
    -webkit-animation-delay: -680ms;
            animation-delay: -680ms;
}
.wave:nth-child(34) .wave_skew {
    -webkit-animation-delay: -680ms;
            animation-delay: -680ms;
}
.wave:nth-child(34) .wave_translate {
    -webkit-animation-delay: -1680ms;
            animation-delay: -1680ms;
}
.wave:nth-child(35) .wave_fade {
    -webkit-animation-delay: -700ms;
            animation-delay: -700ms;
}
.wave:nth-child(35) .wave_skew {
    -webkit-animation-delay: -700ms;
            animation-delay: -700ms;
}
.wave:nth-child(35) .wave_translate {
    -webkit-animation-delay: -1700ms;
            animation-delay: -1700ms;
}
.wave:nth-child(36) .wave_fade {
    -webkit-animation-delay: -720ms;
            animation-delay: -720ms;
}
.wave:nth-child(36) .wave_skew {
    -webkit-animation-delay: -720ms;
            animation-delay: -720ms;
}
.wave:nth-child(36) .wave_translate {
    -webkit-animation-delay: -1720ms;
            animation-delay: -1720ms;
}
.wave:nth-child(37) .wave_fade {
    -webkit-animation-delay: -740ms;
            animation-delay: -740ms;
}
.wave:nth-child(37) .wave_skew {
    -webkit-animation-delay: -740ms;
            animation-delay: -740ms;
}
.wave:nth-child(37) .wave_translate {
    -webkit-animation-delay: -1740ms;
            animation-delay: -1740ms;
}
.wave:nth-child(38) .wave_fade {
    -webkit-animation-delay: -760ms;
            animation-delay: -760ms;
}
.wave:nth-child(38) .wave_skew {
    -webkit-animation-delay: -760ms;
            animation-delay: -760ms;
}
.wave:nth-child(38) .wave_translate {
    -webkit-animation-delay: -1760ms;
            animation-delay: -1760ms;
}
.wave:nth-child(39) .wave_fade {
    -webkit-animation-delay: -780ms;
            animation-delay: -780ms;
}
.wave:nth-child(39) .wave_skew {
    -webkit-animation-delay: -780ms;
            animation-delay: -780ms;
}
.wave:nth-child(39) .wave_translate {
    -webkit-animation-delay: -1780ms;
            animation-delay: -1780ms;
}
.wave:nth-child(40) .wave_fade {
    -webkit-animation-delay: -800ms;
            animation-delay: -800ms;
}
.wave:nth-child(40) .wave_skew {
    -webkit-animation-delay: -800ms;
            animation-delay: -800ms;
}
.wave:nth-child(40) .wave_translate {
    -webkit-animation-delay: -1800ms;
            animation-delay: -1800ms;
}
.wave:nth-child(41) .wave_fade {
    -webkit-animation-delay: -820ms;
            animation-delay: -820ms;
}
.wave:nth-child(41) .wave_skew {
    -webkit-animation-delay: -820ms;
            animation-delay: -820ms;
}
.wave:nth-child(41) .wave_translate {
    -webkit-animation-delay: -1820ms;
            animation-delay: -1820ms;
}
.wave:nth-child(42) .wave_fade {
    -webkit-animation-delay: -840ms;
            animation-delay: -840ms;
}
.wave:nth-child(42) .wave_skew {
    -webkit-animation-delay: -840ms;
            animation-delay: -840ms;
}
.wave:nth-child(42) .wave_translate {
    -webkit-animation-delay: -1840ms;
            animation-delay: -1840ms;
}
.wave:nth-child(43) .wave_fade {
    -webkit-animation-delay: -860ms;
            animation-delay: -860ms;
}
.wave:nth-child(43) .wave_skew {
    -webkit-animation-delay: -860ms;
            animation-delay: -860ms;
}
.wave:nth-child(43) .wave_translate {
    -webkit-animation-delay: -1860ms;
            animation-delay: -1860ms;
}
.wave:nth-child(44) .wave_fade {
    -webkit-animation-delay: -880ms;
            animation-delay: -880ms;
}
.wave:nth-child(44) .wave_skew {
    -webkit-animation-delay: -880ms;
            animation-delay: -880ms;
}
.wave:nth-child(44) .wave_translate {
    -webkit-animation-delay: -1880ms;
            animation-delay: -1880ms;
}
.wave:nth-child(45) .wave_fade {
    -webkit-animation-delay: -900ms;
            animation-delay: -900ms;
}
.wave:nth-child(45) .wave_skew {
    -webkit-animation-delay: -900ms;
            animation-delay: -900ms;
}
.wave:nth-child(45) .wave_translate {
    -webkit-animation-delay: -1900ms;
            animation-delay: -1900ms;
}
.wave:nth-child(46) .wave_fade {
    -webkit-animation-delay: -920ms;
            animation-delay: -920ms;
}
.wave:nth-child(46) .wave_skew {
    -webkit-animation-delay: -920ms;
            animation-delay: -920ms;
}
.wave:nth-child(46) .wave_translate {
    -webkit-animation-delay: -1920ms;
            animation-delay: -1920ms;
}
.wave:nth-child(47) .wave_fade {
    -webkit-animation-delay: -940ms;
            animation-delay: -940ms;
}
.wave:nth-child(47) .wave_skew {
    -webkit-animation-delay: -940ms;
            animation-delay: -940ms;
}
.wave:nth-child(47) .wave_translate {
    -webkit-animation-delay: -1940ms;
            animation-delay: -1940ms;
}
.wave:nth-child(48) .wave_fade {
    -webkit-animation-delay: -960ms;
            animation-delay: -960ms;
}
.wave:nth-child(48) .wave_skew {
    -webkit-animation-delay: -960ms;
            animation-delay: -960ms;
}
.wave:nth-child(48) .wave_translate {
    -webkit-animation-delay: -1960ms;
            animation-delay: -1960ms;
}
.wave:nth-child(49) .wave_fade {
    -webkit-animation-delay: -980ms;
            animation-delay: -980ms;
}
.wave:nth-child(49) .wave_skew {
    -webkit-animation-delay: -980ms;
            animation-delay: -980ms;
}
.wave:nth-child(49) .wave_translate {
    -webkit-animation-delay: -1980ms;
            animation-delay: -1980ms;
}
.wave:nth-child(50) .wave_fade {
    -webkit-animation-delay: -1000ms;
            animation-delay: -1000ms;
}
.wave:nth-child(50) .wave_skew {
    -webkit-animation-delay: -1000ms;
            animation-delay: -1000ms;
}
.wave:nth-child(50) .wave_translate {
    -webkit-animation-delay: -2000ms;
            animation-delay: -2000ms;
}
.wave:nth-child(51) .wave_fade {
    -webkit-animation-delay: -1020ms;
            animation-delay: -1020ms;
}
.wave:nth-child(51) .wave_skew {
    -webkit-animation-delay: -1020ms;
            animation-delay: -1020ms;
}
.wave:nth-child(51) .wave_translate {
    -webkit-animation-delay: -2020ms;
            animation-delay: -2020ms;
}
.wave:nth-child(52) .wave_fade {
    -webkit-animation-delay: -1040ms;
            animation-delay: -1040ms;
}
.wave:nth-child(52) .wave_skew {
    -webkit-animation-delay: -1040ms;
            animation-delay: -1040ms;
}
.wave:nth-child(52) .wave_translate {
    -webkit-animation-delay: -2040ms;
            animation-delay: -2040ms;
}
.wave:nth-child(53) .wave_fade {
    -webkit-animation-delay: -1060ms;
            animation-delay: -1060ms;
}
.wave:nth-child(53) .wave_skew {
    -webkit-animation-delay: -1060ms;
            animation-delay: -1060ms;
}
.wave:nth-child(53) .wave_translate {
    -webkit-animation-delay: -2060ms;
            animation-delay: -2060ms;
}
.wave:nth-child(54) .wave_fade {
    -webkit-animation-delay: -1080ms;
            animation-delay: -1080ms;
}
.wave:nth-child(54) .wave_skew {
    -webkit-animation-delay: -1080ms;
            animation-delay: -1080ms;
}
.wave:nth-child(54) .wave_translate {
    -webkit-animation-delay: -2080ms;
            animation-delay: -2080ms;
}
.wave:nth-child(55) .wave_fade {
    -webkit-animation-delay: -1100ms;
            animation-delay: -1100ms;
}
.wave:nth-child(55) .wave_skew {
    -webkit-animation-delay: -1100ms;
            animation-delay: -1100ms;
}
.wave:nth-child(55) .wave_translate {
    -webkit-animation-delay: -2100ms;
            animation-delay: -2100ms;
}
.wave:nth-child(56) .wave_fade {
    -webkit-animation-delay: -1120ms;
            animation-delay: -1120ms;
}
.wave:nth-child(56) .wave_skew {
    -webkit-animation-delay: -1120ms;
            animation-delay: -1120ms;
}
.wave:nth-child(56) .wave_translate {
    -webkit-animation-delay: -2120ms;
            animation-delay: -2120ms;
}
.wave:nth-child(57) .wave_fade {
    -webkit-animation-delay: -1140ms;
            animation-delay: -1140ms;
}
.wave:nth-child(57) .wave_skew {
    -webkit-animation-delay: -1140ms;
            animation-delay: -1140ms;
}
.wave:nth-child(57) .wave_translate {
    -webkit-animation-delay: -2140ms;
            animation-delay: -2140ms;
}
.wave:nth-child(58) .wave_fade {
    -webkit-animation-delay: -1160ms;
            animation-delay: -1160ms;
}
.wave:nth-child(58) .wave_skew {
    -webkit-animation-delay: -1160ms;
            animation-delay: -1160ms;
}
.wave:nth-child(58) .wave_translate {
    -webkit-animation-delay: -2160ms;
            animation-delay: -2160ms;
}
.wave:nth-child(59) .wave_fade {
    -webkit-animation-delay: -1180ms;
            animation-delay: -1180ms;
}
.wave:nth-child(59) .wave_skew {
    -webkit-animation-delay: -1180ms;
            animation-delay: -1180ms;
}
.wave:nth-child(59) .wave_translate {
    -webkit-animation-delay: -2180ms;
            animation-delay: -2180ms;
}
.wave:nth-child(60) .wave_fade {
    -webkit-animation-delay: -1200ms;
            animation-delay: -1200ms;
}
.wave:nth-child(60) .wave_skew {
    -webkit-animation-delay: -1200ms;
            animation-delay: -1200ms;
}
.wave:nth-child(60) .wave_translate {
    -webkit-animation-delay: -2200ms;
            animation-delay: -2200ms;
}
.wave:nth-child(61) .wave_fade {
    -webkit-animation-delay: -1220ms;
            animation-delay: -1220ms;
}
.wave:nth-child(61) .wave_skew {
    -webkit-animation-delay: -1220ms;
            animation-delay: -1220ms;
}
.wave:nth-child(61) .wave_translate {
    -webkit-animation-delay: -2220ms;
            animation-delay: -2220ms;
}
.wave:nth-child(62) .wave_fade {
    -webkit-animation-delay: -1240ms;
            animation-delay: -1240ms;
}
.wave:nth-child(62) .wave_skew {
    -webkit-animation-delay: -1240ms;
            animation-delay: -1240ms;
}
.wave:nth-child(62) .wave_translate {
    -webkit-animation-delay: -2240ms;
            animation-delay: -2240ms;
}
.wave:nth-child(63) .wave_fade {
    -webkit-animation-delay: -1260ms;
            animation-delay: -1260ms;
}
.wave:nth-child(63) .wave_skew {
    -webkit-animation-delay: -1260ms;
            animation-delay: -1260ms;
}
.wave:nth-child(63) .wave_translate {
    -webkit-animation-delay: -2260ms;
            animation-delay: -2260ms;
}
.wave:nth-child(64) .wave_fade {
    -webkit-animation-delay: -1280ms;
            animation-delay: -1280ms;
}
.wave:nth-child(64) .wave_skew {
    -webkit-animation-delay: -1280ms;
            animation-delay: -1280ms;
}
.wave:nth-child(64) .wave_translate {
    -webkit-animation-delay: -2280ms;
            animation-delay: -2280ms;
}
.wave:nth-child(65) .wave_fade {
    -webkit-animation-delay: -1300ms;
            animation-delay: -1300ms;
}
.wave:nth-child(65) .wave_skew {
    -webkit-animation-delay: -1300ms;
            animation-delay: -1300ms;
}
.wave:nth-child(65) .wave_translate {
    -webkit-animation-delay: -2300ms;
            animation-delay: -2300ms;
}
.wave:nth-child(66) .wave_fade {
    -webkit-animation-delay: -1320ms;
            animation-delay: -1320ms;
}
.wave:nth-child(66) .wave_skew {
    -webkit-animation-delay: -1320ms;
            animation-delay: -1320ms;
}
.wave:nth-child(66) .wave_translate {
    -webkit-animation-delay: -2320ms;
            animation-delay: -2320ms;
}
.wave:nth-child(67) .wave_fade {
    -webkit-animation-delay: -1340ms;
            animation-delay: -1340ms;
}
.wave:nth-child(67) .wave_skew {
    -webkit-animation-delay: -1340ms;
            animation-delay: -1340ms;
}
.wave:nth-child(67) .wave_translate {
    -webkit-animation-delay: -2340ms;
            animation-delay: -2340ms;
}
.wave:nth-child(68) .wave_fade {
    -webkit-animation-delay: -1360ms;
            animation-delay: -1360ms;
}
.wave:nth-child(68) .wave_skew {
    -webkit-animation-delay: -1360ms;
            animation-delay: -1360ms;
}
.wave:nth-child(68) .wave_translate {
    -webkit-animation-delay: -2360ms;
            animation-delay: -2360ms;
}
.wave:nth-child(69) .wave_fade {
    -webkit-animation-delay: -1380ms;
            animation-delay: -1380ms;
}
.wave:nth-child(69) .wave_skew {
    -webkit-animation-delay: -1380ms;
            animation-delay: -1380ms;
}
.wave:nth-child(69) .wave_translate {
    -webkit-animation-delay: -2380ms;
            animation-delay: -2380ms;
}
.wave:nth-child(70) .wave_fade {
    -webkit-animation-delay: -1400ms;
            animation-delay: -1400ms;
}
.wave:nth-child(70) .wave_skew {
    -webkit-animation-delay: -1400ms;
            animation-delay: -1400ms;
}
.wave:nth-child(70) .wave_translate {
    -webkit-animation-delay: -2400ms;
            animation-delay: -2400ms;
}
.wave:nth-child(71) .wave_fade {
    -webkit-animation-delay: -1420ms;
            animation-delay: -1420ms;
}
.wave:nth-child(71) .wave_skew {
    -webkit-animation-delay: -1420ms;
            animation-delay: -1420ms;
}
.wave:nth-child(71) .wave_translate {
    -webkit-animation-delay: -2420ms;
            animation-delay: -2420ms;
}
.wave:nth-child(72) .wave_fade {
    -webkit-animation-delay: -1440ms;
            animation-delay: -1440ms;
}
.wave:nth-child(72) .wave_skew {
    -webkit-animation-delay: -1440ms;
            animation-delay: -1440ms;
}
.wave:nth-child(72) .wave_translate {
    -webkit-animation-delay: -2440ms;
            animation-delay: -2440ms;
}
.wave:nth-child(73) .wave_fade {
    -webkit-animation-delay: -1460ms;
            animation-delay: -1460ms;
}
.wave:nth-child(73) .wave_skew {
    -webkit-animation-delay: -1460ms;
            animation-delay: -1460ms;
}
.wave:nth-child(73) .wave_translate {
    -webkit-animation-delay: -2460ms;
            animation-delay: -2460ms;
}
.wave:nth-child(74) .wave_fade {
    -webkit-animation-delay: -1480ms;
            animation-delay: -1480ms;
}
.wave:nth-child(74) .wave_skew {
    -webkit-animation-delay: -1480ms;
            animation-delay: -1480ms;
}
.wave:nth-child(74) .wave_translate {
    -webkit-animation-delay: -2480ms;
            animation-delay: -2480ms;
}
.wave:nth-child(75) .wave_fade {
    -webkit-animation-delay: -1500ms;
            animation-delay: -1500ms;
}
.wave:nth-child(75) .wave_skew {
    -webkit-animation-delay: -1500ms;
            animation-delay: -1500ms;
}
.wave:nth-child(75) .wave_translate {
    -webkit-animation-delay: -2500ms;
            animation-delay: -2500ms;
}
.wave:nth-child(76) .wave_fade {
    -webkit-animation-delay: -1520ms;
            animation-delay: -1520ms;
}
.wave:nth-child(76) .wave_skew {
    -webkit-animation-delay: -1520ms;
            animation-delay: -1520ms;
}
.wave:nth-child(76) .wave_translate {
    -webkit-animation-delay: -2520ms;
            animation-delay: -2520ms;
}
.wave:nth-child(77) .wave_fade {
    -webkit-animation-delay: -1540ms;
            animation-delay: -1540ms;
}
.wave:nth-child(77) .wave_skew {
    -webkit-animation-delay: -1540ms;
            animation-delay: -1540ms;
}
.wave:nth-child(77) .wave_translate {
    -webkit-animation-delay: -2540ms;
            animation-delay: -2540ms;
}
.wave:nth-child(78) .wave_fade {
    -webkit-animation-delay: -1560ms;
            animation-delay: -1560ms;
}
.wave:nth-child(78) .wave_skew {
    -webkit-animation-delay: -1560ms;
            animation-delay: -1560ms;
}
.wave:nth-child(78) .wave_translate {
    -webkit-animation-delay: -2560ms;
            animation-delay: -2560ms;
}
.wave:nth-child(79) .wave_fade {
    -webkit-animation-delay: -1580ms;
            animation-delay: -1580ms;
}
.wave:nth-child(79) .wave_skew {
    -webkit-animation-delay: -1580ms;
            animation-delay: -1580ms;
}
.wave:nth-child(79) .wave_translate {
    -webkit-animation-delay: -2580ms;
            animation-delay: -2580ms;
}
.wave:nth-child(80) .wave_fade {
    -webkit-animation-delay: -1600ms;
            animation-delay: -1600ms;
}
.wave:nth-child(80) .wave_skew {
    -webkit-animation-delay: -1600ms;
            animation-delay: -1600ms;
}
.wave:nth-child(80) .wave_translate {
    -webkit-animation-delay: -2600ms;
            animation-delay: -2600ms;
}
.wave:nth-child(81) .wave_fade {
    -webkit-animation-delay: -1620ms;
            animation-delay: -1620ms;
}
.wave:nth-child(81) .wave_skew {
    -webkit-animation-delay: -1620ms;
            animation-delay: -1620ms;
}
.wave:nth-child(81) .wave_translate {
    -webkit-animation-delay: -2620ms;
            animation-delay: -2620ms;
}
.wave:nth-child(82) .wave_fade {
    -webkit-animation-delay: -1640ms;
            animation-delay: -1640ms;
}
.wave:nth-child(82) .wave_skew {
    -webkit-animation-delay: -1640ms;
            animation-delay: -1640ms;
}
.wave:nth-child(82) .wave_translate {
    -webkit-animation-delay: -2640ms;
            animation-delay: -2640ms;
}
.wave:nth-child(83) .wave_fade {
    -webkit-animation-delay: -1660ms;
            animation-delay: -1660ms;
}
.wave:nth-child(83) .wave_skew {
    -webkit-animation-delay: -1660ms;
            animation-delay: -1660ms;
}
.wave:nth-child(83) .wave_translate {
    -webkit-animation-delay: -2660ms;
            animation-delay: -2660ms;
}
.wave:nth-child(84) .wave_fade {
    -webkit-animation-delay: -1680ms;
            animation-delay: -1680ms;
}
.wave:nth-child(84) .wave_skew {
    -webkit-animation-delay: -1680ms;
            animation-delay: -1680ms;
}
.wave:nth-child(84) .wave_translate {
    -webkit-animation-delay: -2680ms;
            animation-delay: -2680ms;
}
.wave:nth-child(85) .wave_fade {
    -webkit-animation-delay: -1700ms;
            animation-delay: -1700ms;
}
.wave:nth-child(85) .wave_skew {
    -webkit-animation-delay: -1700ms;
            animation-delay: -1700ms;
}
.wave:nth-child(85) .wave_translate {
    -webkit-animation-delay: -2700ms;
            animation-delay: -2700ms;
}
.wave:nth-child(86) .wave_fade {
    -webkit-animation-delay: -1720ms;
            animation-delay: -1720ms;
}
.wave:nth-child(86) .wave_skew {
    -webkit-animation-delay: -1720ms;
            animation-delay: -1720ms;
}
.wave:nth-child(86) .wave_translate {
    -webkit-animation-delay: -2720ms;
            animation-delay: -2720ms;
}
.wave:nth-child(87) .wave_fade {
    -webkit-animation-delay: -1740ms;
            animation-delay: -1740ms;
}
.wave:nth-child(87) .wave_skew {
    -webkit-animation-delay: -1740ms;
            animation-delay: -1740ms;
}
.wave:nth-child(87) .wave_translate {
    -webkit-animation-delay: -2740ms;
            animation-delay: -2740ms;
}
.wave:nth-child(88) .wave_fade {
    -webkit-animation-delay: -1760ms;
            animation-delay: -1760ms;
}
.wave:nth-child(88) .wave_skew {
    -webkit-animation-delay: -1760ms;
            animation-delay: -1760ms;
}
.wave:nth-child(88) .wave_translate {
    -webkit-animation-delay: -2760ms;
            animation-delay: -2760ms;
}
.wave:nth-child(89) .wave_fade {
    -webkit-animation-delay: -1780ms;
            animation-delay: -1780ms;
}
.wave:nth-child(89) .wave_skew {
    -webkit-animation-delay: -1780ms;
            animation-delay: -1780ms;
}
.wave:nth-child(89) .wave_translate {
    -webkit-animation-delay: -2780ms;
            animation-delay: -2780ms;
}
.wave:nth-child(90) .wave_fade {
    -webkit-animation-delay: -1800ms;
            animation-delay: -1800ms;
}
.wave:nth-child(90) .wave_skew {
    -webkit-animation-delay: -1800ms;
            animation-delay: -1800ms;
}
.wave:nth-child(90) .wave_translate {
    -webkit-animation-delay: -2800ms;
            animation-delay: -2800ms;
}
.wave:nth-child(91) .wave_fade {
    -webkit-animation-delay: -1820ms;
            animation-delay: -1820ms;
}
.wave:nth-child(91) .wave_skew {
    -webkit-animation-delay: -1820ms;
            animation-delay: -1820ms;
}
.wave:nth-child(91) .wave_translate {
    -webkit-animation-delay: -2820ms;
            animation-delay: -2820ms;
}
.wave:nth-child(92) .wave_fade {
    -webkit-animation-delay: -1840ms;
            animation-delay: -1840ms;
}
.wave:nth-child(92) .wave_skew {
    -webkit-animation-delay: -1840ms;
            animation-delay: -1840ms;
}
.wave:nth-child(92) .wave_translate {
    -webkit-animation-delay: -2840ms;
            animation-delay: -2840ms;
}
.wave:nth-child(93) .wave_fade {
    -webkit-animation-delay: -1860ms;
            animation-delay: -1860ms;
}
.wave:nth-child(93) .wave_skew {
    -webkit-animation-delay: -1860ms;
            animation-delay: -1860ms;
}
.wave:nth-child(93) .wave_translate {
    -webkit-animation-delay: -2860ms;
            animation-delay: -2860ms;
}
.wave:nth-child(94) .wave_fade {
    -webkit-animation-delay: -1880ms;
            animation-delay: -1880ms;
}
.wave:nth-child(94) .wave_skew {
    -webkit-animation-delay: -1880ms;
            animation-delay: -1880ms;
}
.wave:nth-child(94) .wave_translate {
    -webkit-animation-delay: -2880ms;
            animation-delay: -2880ms;
}
.wave:nth-child(95) .wave_fade {
    -webkit-animation-delay: -1900ms;
            animation-delay: -1900ms;
}
.wave:nth-child(95) .wave_skew {
    -webkit-animation-delay: -1900ms;
            animation-delay: -1900ms;
}
.wave:nth-child(95) .wave_translate {
    -webkit-animation-delay: -2900ms;
            animation-delay: -2900ms;
}
.wave:nth-child(96) .wave_fade {
    -webkit-animation-delay: -1920ms;
            animation-delay: -1920ms;
}
.wave:nth-child(96) .wave_skew {
    -webkit-animation-delay: -1920ms;
            animation-delay: -1920ms;
}
.wave:nth-child(96) .wave_translate {
    -webkit-animation-delay: -2920ms;
            animation-delay: -2920ms;
}
.wave:nth-child(97) .wave_fade {
    -webkit-animation-delay: -1940ms;
            animation-delay: -1940ms;
}
.wave:nth-child(97) .wave_skew {
    -webkit-animation-delay: -1940ms;
            animation-delay: -1940ms;
}
.wave:nth-child(97) .wave_translate {
    -webkit-animation-delay: -2940ms;
            animation-delay: -2940ms;
}
.wave:nth-child(98) .wave_fade {
    -webkit-animation-delay: -1960ms;
            animation-delay: -1960ms;
}
.wave:nth-child(98) .wave_skew {
    -webkit-animation-delay: -1960ms;
            animation-delay: -1960ms;
}
.wave:nth-child(98) .wave_translate {
    -webkit-animation-delay: -2960ms;
            animation-delay: -2960ms;
}
.wave:nth-child(99) .wave_fade {
    -webkit-animation-delay: -1980ms;
            animation-delay: -1980ms;
}
.wave:nth-child(99) .wave_skew {
    -webkit-animation-delay: -1980ms;
            animation-delay: -1980ms;
}
.wave:nth-child(99) .wave_translate {
    -webkit-animation-delay: -2980ms;
            animation-delay: -2980ms;
}
.wave:nth-child(100) .wave_fade {
    -webkit-animation-delay: -2000ms;
            animation-delay: -2000ms;
}
.wave:nth-child(100) .wave_skew {
    -webkit-animation-delay: -2000ms;
            animation-delay: -2000ms;
}
.wave:nth-child(100) .wave_translate {
    -webkit-animation-delay: -3000ms;
            animation-delay: -3000ms;
}
@-webkit-keyframes fade {
    0% {
        opacity: 0.05;
   }
    5% {
        opacity: 0.05;
   }
    100% {
        opacity: 1;
   }
}
@keyframes fade {
    0% {
        opacity: 0.05;
   }
    5% {
        opacity: 0.05;
   }
    100% {
        opacity: 1;
   }
}
@-webkit-keyframes skew {
    0% {
        -webkit-transform: skewY(-17.5deg);
                transform: skewY(-17.5deg);
   }
    100% {
        -webkit-transform: skewY(17.5deg);
                transform: skewY(17.5deg);
   }
}
@keyframes skew {
    0% {
        -webkit-transform: skewY(-17.5deg);
                transform: skewY(-17.5deg);
   }
    100% {
        -webkit-transform: skewY(17.5deg);
                transform: skewY(17.5deg);
   }
}
@-webkit-keyframes translate {
    0% {
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
   }
    100% {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
   }
}
@keyframes translate {
    0% {
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
   }
    100% {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
   }
}