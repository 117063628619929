.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Overlay-backdrop {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}

.Overlay-top-bar {
  background: red;
  height: 1rem;
  padding: 0.8rem;
  display: grid;
  grid-template-columns: auto auto;
  background: linear-gradient(90deg, rgba(12,9,78,1) 0%, rgba(45,8,84,1) 100%);
  border-bottom: 1px solid #1f053b;
}

.Overlay-title {
  opacity: 0.8;

}

.Overlay-content-outer {
  position: relative;
  z-index: 20;
  background: linear-gradient(45deg, rgba(7,5,57,1) 0%, rgba(34,3,66,1) 100%);
}

.Overlay-close-button {
  text-align: right;
  display: inline-block;
  opacity: 0.7;
  cursor: pointer;
}

.Overlay-close-button:hover {
  opacity: 0.9;
}
