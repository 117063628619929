.warning-banner{
    --tw-bg-opacity: 1;
    background-color: #e8213f;
    text-align:center;
}
.warning-banner p{
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0;
    padding:1.25rem;
    font-weight: 400;
}