.chart-token-selector {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.chart-token-selector--current {
    font-size: 1.4rem;
    font-weight: 700;
    margin-right: 10px;
    color: white;
}

.chart-token-menu-items.menu-items {
    width: 158px;
    top: 60px;
    right: unset;
    left: 0;
}
