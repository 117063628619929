.Home {
  min-height: 100vh;
  /* padding-bottom: calc(3rem + 200px); */
}

.default-container{
  padding:0px 16px;
}

.Home-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 62px;
  position: relative;
  /* height: 52.581rem; */
  padding-bottom: 4.129rem;
  /* background: url("../../img/home-bg.png"); */
  /* background: url("../../img/Artwork_gmx.svg"); */
  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-top: -62px;
}

.Home-title {
  font-size: 3.677rem;
  line-height: 4.387rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0;
}

.Home-title-section-container {
  position: relative;
  z-index: 1;
  width: 100%;
  /* padding-top: 7.548rem; */
  padding-top: 4rem;
}

.Home-title-section {
  max-width: 33.677rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Home-description {
  font-size: 1.29rem;
  line-height: 1.613rem;
  max-width: 28.645rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.Home-description p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}

.Home-benefit-description p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}


.Home-benefits-section {
  background: #000526;
  position: relative;
  z-index: 2;
  padding:0 16px;
}

.Home-benefits {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 3.6135rem;
  padding-bottom: 3.6135rem;
  grid-gap: 10px;
  gap: 10px;
}

.Home-benefit {
  padding: 1.5485rem 0;
  flex: 1 0 32%;
  min-width: 300px;
}

.Home-benefit-title {
  font-size: 1.419rem;
  line-height: 1.806rem;
  font-weight: normal;
  font-family:'Etna',sans-serif;
  letter-spacing: 1.5px;
/*   margin-left: 1rem; */
}

.Home-benefit-description {
  font-size: 1.161rem;
  line-height: 1.484rem;
  max-width: 18.839rem;
  color: #fff;
  letter-spacing: 0;
}

.Home-benefit-icon {
  display: flex;
  align-items: center;
  padding: 0 0 1rem;
  font-size: 1.2rem;
}

.Home-cta-section {
/*   background: #101124; */
position: relative;
z-index: 2;
}

.Home-cta-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 5.161rem;
  padding-bottom: 5.161rem;
  align-items: center;
}

.Home-cta-info {
  max-width: 28rem;
  padding:0px 16px;
}

.Home-cta-info__title {
  font-size: 2.452rem;
  line-height: 3.097rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family:'Etna',sans-serif;
  letter-spacing: 1.5px;
}

.Home-cta-info__description {
  font-size: 1.161rem;
  line-height: 1.484rem;
  color: #fff;
  margin-bottom: 1.5rem;
  
}
.Home-cta-info__description p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}

.Home-cta-options {
  max-width: 38rem;
  width: 100%;
}

.Home-cta-option-title {
  font-size: 1.548rem;
  line-height: 2.065rem;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  color: #000;
}

.Home-cta-option-subtitle {
  font-size: 1.161rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #c4b9a0;
  margin-bottom: 3px;
}

.home-tag-line{
  font-size: 24.5px;
    margin-top: 10px;
}

.Home-cta-button {
  display: block;
  padding: 1rem;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  margin-top: 2rem;
}

.Home-cta-option-icon {
  display: flex;
  align-items: center;
/*   width: 96px; */
  height: 96px;
  justify-content: center;
}

@media (min-width: 650px) {
  .Home-cta-option {
    max-width: 38rem;
    width: 100%;
    /* border: 1px solid #1e2136; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: flex;
    justify-content: center;
  }
  .Home-cta-option-info {
    text-align: right;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items: center;
  }
  .Home-cta-option-action {
    display: flex;
    justify-content: flex-end;
    margin-top:25px;
  }
  .Home-cta-option-fantom {
  }
  
 }

 @media (max-width: 650px) {

  .Home-cta-option {
    max-width: 38rem;
    width: 100%;
    box-shadow: 0px 7px 12px #0000004d;
    /* border: 1px solid #1e2136; */
    padding: 1.032rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: block;
    justify-content: space-between;
  }
  .imagescreem{
    width: 80%;
   
  }
  .Home-cta-option-info {
    text-align: center;
  }
  .Home-cta-option-action {
    display: flex;
    justify-content: center;
  }
  .Home-cta-option-fantom {

  }
  
 }



.Home-cta-option + .Home-cta-option {
  margin-top: 2.645rem;
}
.Home-token-card-section {
padding: 5rem 16px;
/*   background: #17182c; */
  margin-bottom: -3rem;
}

.Home-token-card-info {
text-align:center;
}

.Home-token-card-info__title {
  font-size: 2.452rem;
  line-height: 3.097rem;
  font-weight: bold;
  margin-bottom: 2rem;
  font-family:'Etna',sans-serif;
  letter-spacing: 1.5px;
}

.Home-token-card-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.Home-token-card-option {
  position: relative;
  padding: 1.5rem;
  border-radius: 13px;
/*   max-width: 38.19rem; */
  /* background: #000526;
  box-shadow: 0px 5px 13px #00000085;
  border: 1px solid #000526;
  border-radius: 4px; */
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
}

.Home-token-card-option-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0px;
  color: #ffffff;
  height: 2rem;
}

.Home-token-card-option-icon img {
  margin-right: 0.5rem;
}

.Home-token-card-option-title {
  font-size: 1.161rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.5rem;
}
.Home-token-card-option-title p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}

.Home-token-card-option-apr {
  font-size: 1rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.25rem;
}

.Home-token-card-option-action {
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
}

.Home-token-card-option-action > .buy a:first-child {
  margin-right: 1rem;
}

/* .Home-token-card-option-action .read-more {
  color: rgb(10 211 215) !important;
  border-color: rgba(10 211 215 0.6);
} */

/* .default-btnhome.read-more {
  color: rgb(10 211 215) !important;
  border-color: rgba(10, 211, 215, 0.6);
  background: none;
} */

.Home-latest-info-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 4rem;
  padding-bottom: 1rem;
  width: 100%;
  flex-wrap: wrap;
  gap:10px;
}

.Home-latest-info-block {
  /* background: #17182C 0% 0% no-repeat padding-box; */
  box-shadow: 0px 5px 22px #0000001f;
  box-shadow: 0px 12px 17px #00000024;
  box-shadow: 0px 7px 8px #00000033;
  /* mix-blend-mode: multiply; */
  border: 1px solid #ffffff14;
  border-radius: 4px;
  max-width: 24.526rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1.806rem;
  padding-bottom: 1.806rem;
  margin: 1.032rem 0;
  backdrop-filter: blur(30px);
  flex: 1 0 33%;
  margin: 1em 0;
  min-width: 300px;
}

.Home-latest-info__icon {
  margin-left: 1.645rem;
  margin-right: 1.048rem;
}

.Home-latest-info__title {
  color: #fff;
  font-size: 1.032rem;
  line-height: 1.613rem;
  letter-spacing: 0px;
}

.Home-latest-info__value {
  color: white;
  font-size: 1.936rem;
  line-height: 2.516rem;
  letter-spacing: 0px;
  font-weight:bold;
}

@media (max-width: 400px) {
 .Home-latest-info__value {
  color: white;
  font-size: 1.336rem;
  }
}

.Home-latest-info__description {
  letter-spacing: 0px;
  color: #c4b9a0;
  font-size: 0.903rem;
  line-height: 1.613rem;
}

.Home-video-section {
  background: #101124;
  padding: 5.161rem 0;
}

.Home-video-block {
  background: #0b131c 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px #0000003d;
  border-radius: 7px;
  max-width: 57.677rem;
  max-height: 32.645rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.Home-faqs-section {
  background: #101124;
  padding: 5.161rem 0;
}

.Home-faqs-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Home-faqs-documentation {
  margin-bottom: 3.163rem;
}

.Home-faqs-introduction__title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 2.452rem;
  line-height: 3.097rem;
}

.Home-faqs-introduction__description {
  letter-spacing: 0px;
  color: #c4b9a0;
  font-size: 1.161rem;
  line-height: 1.484rem;
  margin-bottom: 2.387rem;
  margin-top: 1rem;
  max-width: 19.226rem;
}

.Home-faqs-content-block {
  max-width: 52rem;
  width: 100%;
}

.Home-faqs-content {
  background: #000526;
  border: 1px solid #000526;
  border-radius: 4px;
  cursor: pointer;
  padding: 2.387rem 3.226rem;
  width: 100%;
  max-width: 52rem;
}

.Home-faqs-content:hover {
  background: #433c0055;
}

.Home-faqs-content + .Home-faqs-content {
  margin-top: 1rem;
}

.Home-faqs-content-header {
  display: flex;
}

.Home-faqs-content-header__icon {
  display: flex;
  align-items: center;
  margin-right: 1.139rem;
  font-size: 0.89rem;
  color: #000526;
}

.Home-faqs-content-header__text {
  font-size: 1.097rem;
  line-height: 1.419rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.Home-faqs-content-main {
  overflow: hidden;
  height: 0px;
  visibility: hidden;
  padding: 0 0rem 0 2rem;
  letter-spacing: 0px;
  color: #c4b9a0;
  transition: all 0.3s ease-in-out 0s;
}

.Home-faqs-content-main.opened {
  height: fit-content;
  visibility: visible;
  padding: 1rem 0rem 0 2rem;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1000px) {
  .Home-token-card-option-action {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
  .Home-token-card-option-action a {
    justify-content: center;
    display: inline-block;
  }
  .Home-token-card-option-action a {
    text-align: center;
  }
  .Home-token-card-option-action > .buy {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
  .Home-token-card-option-action > .read-more {
    display: inline-block;
  }
  .Home-token-card-option-action > .buy a:first-child {
    margin-right: 0;
  }
}

@media (max-width: 750px) {
  .Home-token-card-options {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
}

@media (max-width: 500px) {
  .Home-title-section-container {
    padding-top: 4.774rem;
  }

  .Home-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    letter-spacing: 0;
  }

  .Home-cta-options {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

 /*  .Home-cta-option-info {
    width: 50%;
  } */

  .Home-cta-option-subtitle {
    margin-bottom: 4px;
  }

  .Home-cta-option-title {
    line-height: 1.548rem;
  }

  .Home-cta-option-action {
    margin-top: 0.5rem;
  }

  .Home-latest-info-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 4.774rem;
  }

  .Home-latest-info__icon {
    margin-right: 1.903rem;
  }

  .Home-latest-info-block + .Home-latest-info-block {
    margin-top: 16px;
  }

  .Home-faqs-content {
    padding-right: 1rem;
  }
}

@media (max-width: 400px) {
  .Home-description {
    font-size: 1rem;
    line-height: 1.4rem;
    max-width: 28.645rem;
    color: #fff;
    margin-bottom: 1.677rem;
  }
  /*
  .Home-token-card-option-action {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .Home-token-card-option-action .default-btn {
    margin: 0.5rem;
  } */
}

@media (max-width: 350px) {
  .Home-title {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }

  .Home-latest-info__icon {
    margin: 0 1rem;
  }
}

.iconsize{
  width: 100%;
}
@media (max-width: 400px) {
  .iconsize{
  width: 80%;
  }
}
@media (max-width: 580px) {
  .iconsize{
  width: 80%;
  }
}
.titletextcolor{
  color: rgb(59 130 245);
}
.borderradius{
  border-radius: 15px;
}

.homepage-container-pd{
  padding-left: 16px!important;
  padding-right: 16px!important;
}

  
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

:root {
  --side: #1969ff;
  --side-dark: #1a1a1b;
  --coin-size: 250px;
  --coin-face: url('https://s3.coinmarketcap.com/static/img/portraits/62d51d9af192d82df8ff3a83.png');
}


.fantomCoin {
  height: var(--coin-size);
  width: var(--coin-size);
  margin: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fantomCoin::before {
  content: '';
  display: block;
  position: relative;
  height: var(--coin-size);
  width: var(--coin-size);
  border-radius: 50%;
  background-color: var(--face);
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  background-image: var(--coin-face);
  background-size: 100% 100%;
  background-position: center;
  background-blend-mode: overlay;
}

/* animation definitions */

@-webkit-keyframes spin {
  0% {
    width: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    -webkit-box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
            box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    -webkit-transform: translateX(-0.375rem);
            transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    -webkit-box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
            box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    -webkit-transform: translateX(0.375rem);
            transform: translateX(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    width: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@keyframes spin {
  0% {
    width: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    -webkit-box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
            box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    -webkit-transform: translateX(-0.375rem);
            transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    -webkit-box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
            box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    -webkit-transform: translateX(0.375rem);
            transform: translateX(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    width: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@-webkit-keyframes flip {
  0% {
    height: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    height: 0.1rem;
    -webkit-box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
            box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
    -webkit-transform: translateY(-0.375rem);
            transform: translateY(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    height: 0.1rem;
    -webkit-box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
            box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
    -webkit-transform: translateY(0.375rem);
            transform: translateY(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    height: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@keyframes flip {
  0% {
    height: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    height: 0.1rem;
    -webkit-box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
            box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
    -webkit-transform: translateY(-0.375rem);
            transform: translateY(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    height: 0.1rem;
    -webkit-box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
            box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
    -webkit-transform: translateY(0.375rem);
            transform: translateY(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    height: var(--coin-size);
    -webkit-box-shadow:
      0 0 0 var(--side-dark);
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@media (max-width: 1000px) {
  .Home-cta-option-info {
    display: none;
  }
  .Home-cta-container{
    justify-content: center;
  }
  .Home-cta-info{
    background: rgb(17 20 58);
    border: 1px solid rgb(28 36 75);
    padding: 25px;
    border-radius: 15px;
    width: 100%;
    max-width:calc(100% - 16px);
  }
  .Home-cta-info__description{
    margin-bottom:25px;
  }
  .Home-cta-info__title{
    margin-bottom:25px;
  }
}