.TradeHistory-row {
  font-size: 0.9rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.TradeHistory-time {
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}
.textcolor{
  color: white;
}
.textcolor:hover{
  color: rgb(59 130 245);
}